import Image from 'next/image';
import { useRouter } from 'next/router';
import { Highlight } from 'react-instantsearch';

import { CustomAnchor, IconWithLabel, Text } from '@/components/atomic/atoms';
import { BID_STATUS } from '@/config/bid';
import { staticMediaStoreBaseURL } from '@/config/common';
import { TAB_CATEGORY_CONFIG_LIST } from '@/config/searchkit/bidList';
import { convertToStandardDateFormat, formatTime } from '@/lib/dateAndTime';
import { useIsMobile } from '@/lib/screenResolution';
import { PAGE_NAME, getPageURL } from '@/services/partnerPortal.service';

const IconTextPair = ({
  imageProps: { alt, iconWidth, iconHeight, src },
  content
}) => (
  <div className='flex gap-3'>
    <Image
      {...{
        alt,
        height: 0,
        src,
        style: { width: iconWidth, height: iconHeight },
        width: 0
      }}
    />
    <div className='flex flex-1'>
      <Text
        {...{
          content,
          className: `text-sm md:text-base font-medium max-w-64 md:max-w-128 truncate text-dim-gray self-center`
        }}
      />
    </div>
  </div>
);

const EventLocation = ({ bidListTabCategory, isMobile, hit }) => (
  <IconTextPair
    {...{
      bidListTabCategory,
      content: (
        <Highlight
          {...{
            attribute: 'eventLocation',
            hit
          }}
        />
      ),
      imageProps: {
        alt: 'location',
        iconHeight: isMobile ? 20 : 24,
        iconWidth: isMobile ? 20 : 24,
        src: `${staticMediaStoreBaseURL}/icons/${
          bidListTabCategory === BID_STATUS.EXPIRED.value
            ? 'location-icon-gray.svg'
            : 'location-icon-with-frame.svg'
        }`
      }
    }}
  />
);

const EventDate = ({
  bidListTabCategory,
  eventStartTime,
  isMobile,
  userEventDate
}) => (
  <IconTextPair
    {...{
      bidListTabCategory,
      content: `${convertToStandardDateFormat(userEventDate)} - ${formatTime(
        eventStartTime
      )}`,
      imageProps: {
        alt: 'timer',
        iconHeight: isMobile ? 20 : 24,
        iconWidth: isMobile ? 20 : 24,
        src: `${staticMediaStoreBaseURL}/icons/${
          bidListTabCategory === BID_STATUS.EXPIRED.value
            ? 'calendar-gray.svg'
            : 'calendar-red-with-frame.svg'
        }`
      }
    }}
  />
);

const EventInfo = ({
  bidListTabCategory,
  bidTitle,
  className,
  content,
  hit,
  isMobile
}) => (
  <div className='flex flex-col gap-2 relative'>
    <div className='flex justify-between'>
      <Text
        {...{
          className:
            'text-base text-black md:text-xl font-medium max-w-48 md:max-w-none truncate md:whitespace-nowrap',
          content: bidTitle
        }}
      />
      <div className='flex flex-row-reverse gap-2'>
        <IconWithLabel
          {...{
            containerClass: `hidden md:flex items-center py-1 px-2 gap-1 bg-brand-gradient rounded`,
            imgAlt: 'cart number',
            imgHeight: 16,
            imgSrc: 'cart-number-white-icon.svg',
            imgWidth: 16,
            value: (
              <span>
                {'# '}
                <Highlight
                  {...{
                    attribute: 'quoteNumber',
                    hit
                  }}
                />
              </span>
            ),
            valueStyle: 'text-xs md:text-sm font-medium text-white'
          }}
        />

        <Text
          {...{
            className: `${className} px-2 md:px-3 font-medium py-1 rounded text-xs md:text-sm`,
            content
          }}
        />
      </div>
    </div>
    <IconWithLabel
      {...{
        containerClass: `flex md:hidden self-start items-center py-1 px-2 gap-1 bg-brand-gradient rounded`,
        imgAlt: 'cart number',
        imgHeight: 16,
        imgSrc: 'cart-number-white-icon.svg',
        imgWidth: 16,
        value: (
          <span>
            {'# '}
            <Highlight
              {...{
                attribute: 'quoteNumber',
                hit
              }}
            />
          </span>
        ),
        valueStyle: 'text-xs md:text-sm font-medium text-white'
      }}
    />
    <Image
      {...{
        alt: 'right angel',
        className: 'absolute right-4 top-20 md:top-10',
        height: 0,
        src: `${staticMediaStoreBaseURL}/icons/${
          bidListTabCategory === BID_STATUS.EXPIRED.value
            ? 'right-angle-gray-bg.svg'
            : 'right-angle-red-bg.svg'
        }`,
        style: {
          width: isMobile ? 20 : 24,
          height: isMobile ? 20 : 24
        },
        width: 0
      }}
    />
  </div>
);

const getBidListTabLabelConfiguration = ({ bidListTabCategory }) => {
  const { className, label } = TAB_CATEGORY_CONFIG_LIST.find(
    ({ key }) => key === bidListTabCategory
  );

  const inActiveBidLabelClass =
    'bg-white text-dim-gray text-xs md:text-sm font-medium rounded';
  return {
    content: label,
    className:
      bidListTabCategory === BID_STATUS.EXPIRED.value
        ? inActiveBidLabelClass
        : className
  };
};

const BidCard = ({ hit: { hit } }) => {
  const {
    bidListTabCategory,
    eventDate: userEventDate,
    eventStartTime,
    eventType: bidTitle,
    referenceId,
    viewBid = () => {}
  } = hit;

  const { content, className } = getBidListTabLabelConfiguration({
    bidListTabCategory
  });

  const router = useRouter();

  const [isMobile] = useIsMobile();
  return (
    <div className='w-full md:w-58 mx-auto md:ml-0'>
      <CustomAnchor
        {...{
          onClick: () => {
            router.push(
              getPageURL({
                pageName: PAGE_NAME.BID_DETAILS.label,
                pathParams: { referenceId }
              })
            );
          }
        }}
      >
        <div
          className={`${
            bidListTabCategory === BID_STATUS.EXPIRED.value
              ? 'bg-neutral'
              : 'bg-white'
          } w-full border-neutral border px-4 md:px-8 py-5 flex flex-col gap-4 rounded-2xl shadow-card mb-6 z-50 cursor-pointer`}
          onClick={viewBid}
        >
          <EventInfo
            {...{
              bidListTabCategory,
              bidTitle,
              className,
              content,
              eventStartTime,
              hit,
              isMobile,
              userEventDate
            }}
          />
          <div className='flex flex-col md:flex-row gap-4'>
            <EventDate
              {...{
                bidListTabCategory,
                eventStartTime,
                isMobile,
                userEventDate
              }}
            />
            <EventLocation {...{ bidListTabCategory, hit, isMobile }} />
          </div>
        </div>
      </CustomAnchor>
    </div>
  );
};

export default BidCard;
