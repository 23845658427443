import Image from 'next/image';

import {
  IconWithLabel,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { ORDER_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { convertToStandardDateFormat } from '@/lib/dateAndTime';
import { parseNumberedLabel } from '@/lib/utils';

const getStatusBasedOptions = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        dateIcon: 'calendar-gray-with-frame.svg',
        locationIcon: 'location-icon-gray.svg',
        orderNumberClass: 'bg-dim-gray',
        orderStatusSpecificClassName: 'cancelled-order',
        userIcon: 'user-icon-red-gray.svg'
      };
      break;
    default:
      dictionary = {
        dateIcon: 'calendar-red.svg',
        locationIcon: 'location-icon.svg',
        orderNumberClass: 'bg-planner-cart-bg',
        userIcon: 'user-icon-red.svg'
      };
      break;
  }
  return dictionary;
};

const OrderEventInfoMobile = ({ eventInfo, exactAddress, status }) => {
  const { eventDate, eventName, orderNumber, plannerName } = eventInfo || {};
  const {
    dateIcon,
    locationIcon,
    orderNumberClass,
    orderStatusSpecificClassName = '',
    userIcon
  } = getStatusBasedOptions({ status });

  return (
    <div className='relative -mt-20'>
      <div className='bg-white px-4 md:px-8 py-5 flex flex-col gap-4 rounded-2xl shadow-md mx-6 z-50'>
        <div className='flex flex-col gap-2'>
          <Text
            {...{
              content: eventName,
              className: `text-lg md:text-2xl font-medium ${orderStatusSpecificClassName}`
            }}
          />
          <div className='flex flex-row justify-between'>
            <IconWithLabel
              {...{
                containerClass: `flex items-center px-2 py-1 self-baseline flex gap-2 rounded ${orderNumberClass}`,
                imgAlt: 'order number',
                imgHeight: 16,
                imgSrc: 'order-number-white-icon.svg',
                imgWidth: 16,
                value: parseNumberedLabel(orderNumber),
                valueStyle: 'text-white text-xs md:text-sm font-medium'
              }}
            />
            {status === ORDER_STATUS.CANCELLED.value && (
              <IconWithLabel
                {...{
                  containerClass: 'flex gap-1 items-center md:gap-2',
                  imgAlt: 'cancelled order',
                  imgHeight: 16,
                  imgSrc: 'cancelled-order-icon.svg',
                  imgWidth: 16,
                  value: 'Event Cancelled',
                  valueStyle:
                    'text-error-base text-sm font-medium flex self-center'
                }}
              />
            )}
          </div>
        </div>
        <div className='flex gap-3'>
          <div className='w-6 md:w-8 self-center'>
            <Image
              alt='calendar'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${dateIcon}`}
              style={{ width: 24, height: 24 }}
              width={0}
            />
          </div>
          <TextKeyValuePair
            {...{
              className: 'flex-col gap-1',
              label: 'Event Date',
              labelClass:
                'text-xs md:text-sm font-medium text-silver uppercase',
              spaceTop: '',
              value: convertToStandardDateFormat(eventDate),
              valueClassName: `text-sm md:text-base text-nero font-medium ${orderStatusSpecificClassName}`
            }}
          />
        </div>
        <div className='flex gap-3'>
          <div className='w-6 md:w-8 self-center'>
            <Image
              alt='location'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${locationIcon}`}
              style={{ width: 24, height: 24 }}
              width={0}
            />
          </div>
          <div className='flex flex-col self-center gap-1 flex-1'>
            <Text
              {...{
                content: 'Location',
                className: `text-xs md:text-sm font-medium text-silver uppercase`
              }}
            />
            <Text
              {...{
                content: exactAddress,
                className: `text-sm md:text-base text-nero font-medium ${orderStatusSpecificClassName}`
              }}
            />
          </div>
        </div>
        <div className='flex gap-3'>
          <div className='w-6 md:w-8 self-center'>
            <Image
              alt='user'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/${userIcon}`}
              style={{ width: 28, height: 28 }}
              width={0}
            />
          </div>
          <TextKeyValuePair
            {...{
              className: 'flex-col self-center gap-1 flex-1',
              label: 'Event Planner',
              labelClass:
                'text-xs md:text-sm font-medium text-silver uppercase',
              spaceTop: '',
              value: plannerName,
              valueClassName: `text-sm md:text-base text-nero font-medium ${orderStatusSpecificClassName}`
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderEventInfoMobile;
