import { SearchBox } from 'react-instantsearch';

import {
  ScopeSearchAttributeModal,
  SortResultsModal as SortResultsModalWeb
} from '@/components/atomic/atoms';
import { SORT_BY_ITEMS_BID_LIST } from '@/config/searchkit/bidList';
import { SORT_BY_ITEMS_ORDER_LIST } from '@/config/searchkit/orderList';

import { getPlaceholderTextForSearchBox } from '@/helpers/searchkit';

const sortByItemsMap = {
  bidList: SORT_BY_ITEMS_BID_LIST,
  orderList: SORT_BY_ITEMS_ORDER_LIST
};

const SearchBoxFilter = ({
  searchAttributeLabelList = [],
  searchableAttribute,
  setSearchableAttribute,
  source
}) => (
  <div className='flex gap-2 h-10 md:h-13.5 mt-4'>
    <div className='flex justify-between rounded-full relative w-full md:w-54.7'>
      <div>
        <SearchBox
          {...{
            classNames: {
              form: 'relative searchkit-searchbox before:bg-searchkit-search-box-form rounded-full h-8 md:h-[52px] w-full md:w-auto',
              input: `relative w-84 md:w-54.7 rounded-full border border-platinum shadow-box h-9 md:h-[52px] focus:border-brand !pl-[15rem] text-sm font-medium`
            },
            placeholder: getPlaceholderTextForSearchBox({
              searchableAttribute,
              searchAttributeLabelList
            })
          }}
        />
      </div>
      <ScopeSearchAttributeModal
        {...{
          searchableAttribute,
          searchAttributeLabelList,
          setSearchableAttribute
        }}
      />
    </div>
    <SortResultsModalWeb
      {...{
        className: 'hidden md:flex',
        items: sortByItemsMap[source]
      }}
    />
  </div>
);

export default SearchBoxFilter;
