import Image from 'next/image';
import { useState } from 'react';

import {
  IconWithLabel,
  Text,
  TextKeyValuePair
} from '@/components/atomic/atoms';
import { ORDER_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { convertToDateTimeString } from '@/lib/dateAndTime';
import { useIsMobile } from '@/lib/screenResolution';
import { noProductImageURL } from '@/services/partnerPortal.service';

const getStatusBasedParameters = ({ status }) => {
  let dictionary;
  switch (status) {
    case ORDER_STATUS.CANCELLED.value:
      dictionary = {
        orderStatusSpecificClassName: 'cancelled-order',
        rightAngleIcon: 'right-angle-gray-bg.svg'
      };
      break;
    default:
      dictionary = {
        rightAngleIcon: 'right-angle-red-bg.svg'
      };
      break;
  }
  return dictionary;
};

const OrderItemCard = ({
  deliveryDate,
  deliveryTime,
  id,
  pickupDate,
  pickupTime,
  productMediaList,
  productName,
  quantity,
  setIdOfOrderItemInDetailView,
  status
}) => {
  const [isMobile] = useIsMobile();
  const [imageURL, setImageURL] = useState(getImageURL(productMediaList[0]));
  const { orderStatusSpecificClassName = '', rightAngleIcon } =
    getStatusBasedParameters({
      status
    });

  const deliveryDateTime = deliveryDate
    ? convertToDateTimeString({ date: deliveryDate, time: deliveryTime })
    : '';
  const pickupDateTime = pickupDate
    ? convertToDateTimeString({ date: pickupDate, time: pickupTime })
    : '';

  return (
    <div
      onClick={() => setIdOfOrderItemInDetailView(id)}
      className={`ml-5 mr-3 pr-6 md:mx-0 rounded-xl my-4 h-26 md:h-36 border border-neutral bid-item-summary text-nero cursor-pointer`}
    >
      <div className='flex gap-3'>
        {productMediaList?.length > 0 ? (
          <div className='w-18 md:w-40 h-26 md:h-36 relative image-bid-summary'>
            <Image
              {...{
                alt: 'event',
                className: 'rounded-l-xl',
                fill: true,
                onError: () => setImageURL(noProductImageURL),
                priority: true,
                sizes: '(max-width: 600px) 100vw, 50vw',
                src: imageURL
              }}
            />
          </div>
        ) : (
          <div>
            <Image
              {...{
                alt: 'default image',
                className: 'rounded-l-xl',
                fill: true,
                src: noProductImageURL,
                priority: true,
                sizes: '(max-width: 600px) 100vw, 50vw'
              }}
            />
          </div>
        )}
        <div className='flex flex-col flex-1 self-center'>
          <Text
            {...{
              content: productName,
              className: `text-sm md:text-lg font-medium truncate max-w-36 md:max-w-80 ${orderStatusSpecificClassName}`
            }}
          />
          <TextKeyValuePair
            {...{
              className: 'gap-2 mt-1',
              label: 'Quantity:',
              labelClass: `text-xs md:text-base font-medium self-center ${orderStatusSpecificClassName}`,
              spaceTop: '',
              value: quantity,
              valueClassName: `text-xs md:text-base font-light ${orderStatusSpecificClassName}`
            }}
          />
          {deliveryDate && (
            <IconWithLabel
              {...{
                containerClass: `flex items-center gap-2 mt-1 py-1 text-green-base bg-green-light rounded self-baseline px-2`,
                imgAlt: 'delivery',
                imgHeight: 16,
                imgSrc: 'truck-green-icon.svg',
                imgWidth: 16,
                value: deliveryDateTime,
                valueStyle: 'text-xss md:text-xs font-medium'
              }}
            />
          )}
          {pickupDate && (
            <IconWithLabel
              {...{
                containerClass: `flex items-center gap-2 mt-1 py-1 text-warning-dark bg-warning-light rounded self-baseline px-2`,
                imgAlt: 'delivery',
                imgHeight: 16,
                imgSrc: 'truck-yellow-icon.svg',
                imgWidth: 16,
                value: pickupDateTime,
                valueStyle: 'text-xss md:text-xs font-medium'
              }}
            />
          )}
        </div>
        <div className='flex flex-col justify-center'>
          <div className='cursor-pointer'>
            <Image
              alt='arrow'
              height={0}
              onClick={() => setIdOfOrderItemInDetailView(id)}
              src={`${staticMediaStoreBaseURL}/icons/${rightAngleIcon}`}
              style={{
                width: isMobile ? 16 : 32,
                height: isMobile ? 16 : 32
              }}
              width={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemCard;
